import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SVG from '../js/svg.svg'
import StagRear from '../images/stag-rear-one.svg'
import Particles from 'react-particles-js'
const Stag = props => {
    return (
        <div className="h-full w-full"  style={{minHeight: '50vh'}}>
            <Particles
            className="h-full w-full md:h-screen"
            style={{minHeight: '50vh'}}
                params={
                    {
                        detectRetina: false,
                        fpsLimit: 24,
                        interactivity: {
                            detectsOn: "canvas",
                            events: {
                                // onClick: {
                                //     enable: true,
                                //     mode: "slow"
                                // },
                                // onDiv: {
                                //     elementId: "repulse-div",
                                //     enable: true,
                                //     mode: "slow"
                                // },
                                onHover: {
                                    enable: true,
                                    mode: "connect",
                                    parallax: {
                                        enable: false,
                                        force: 1,
                                        smooth: 1
                                    }
                                },
                                resize: true
                            },
                            modes: {
                                // bubble: {
                                //     distance: 20,
                                //     duration: 2,
                                //     opacity: 10,
                                //     size: 3,
                                //     speed: 3
                                // },
                                connect: {
                                    distance: 20,
                                    lineLinked: { opacity: 0.8 },
                                    radius: 50
                                },
                                // grab: {
                                //     distance: 400,
                                //     lineLinked: { opacity: 1 }
                                // },
                                // push: { quantity: 4 },
                                // remove: { quantity: 2 },
                                // repulse: {
                                //     distance: 200,
                                //     duration: 0.4
                                // },
                                // slow: {
                                //     active: true,
                                //     radius: 1,
                                //     factor: 2
                                // }
                            }
                        },
                        particles: {
                            color: { value: "#2D3748" },
                            lineLinked: {
                                blink: false,
                                color: "#2D3748",
                                consent: false,
                                distance: 25,
                                enable: true,
                                opacity: 0.8,
                                width: 1
                            },
                            move: {
                                //   attract: {
                                //     enable: true,
                                //     rotate: {
                                //       x: 600,
                                //       y: 1200
                                //     }
                                //   },
                                bounce: false,
                                //   direction: "none",
                                enable: true,
                                //   outMode: "bounce",
                                //   random: true,
                                speed: 0.2,
                                straight: false
                            },
                            number: {
                                density: {
                                    enable: false,
                                    area: 1000
                                },
                                limit: 50,
                                value: 400
                            },
                            opacity: {
                                animation: {
                                    enable: true,
                                    minimumValue: 0.05,
                                    speed: 0.4,
                                    sync: true
                                },
                                random: false,
                                value: 1
                            },
                            shape: {
                                //   character: {
                                //     fill: false,
                                //     font: "Verdana",
                                //     style: "",
                                //     value: "*",
                                //     weight: "400"
                                //   },
                                //   image: {
                                //     height: 100,
                                //     replaceColor: true,
                                //     src: "images/github.svg",
                                //     width: 100
                                //   },
                                //   polygon: { sides: 5 },
                                //   stroke: {
                                //     color: "#000000",
                                //     width: 0
                                //   },
                                //   type: "circle"
                            },
                            size: {
                                animation: {
                                    enable: false,
                                    minimumValue: 0.1,
                                    speed: 10,
                                    sync: false
                                },
                                random: true,
                                value: 1
                            }
                        },
                        polygon: {
                            draw: {
                                enable: true,
                                lineColor: "rgba(255,255,255,0.2)",
                                lineWidth: 0.9
                            },
                            move: {
                                radius: 4
                            },
                            inlineArrangement: "equidistant",
                            scale: 0.8,
                            type: "inline",
                            url: StagRear
                        }
                    }
                } />
        </div>

    )
}

Stag.propTypes = {

}

export default Stag
