import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Footer from "./Footer"
import Nav from "./Nav"
import AOS from 'aos'
import 'aos/dist/aos.css'
import CookieConsent from "react-cookie-consent";
import { Button } from '@elements'

const Layout = ({ children }) => {

  useEffect(() => {
    AOS.init();
  }, [])




  let leftItems = [
    {
      linkTo: '/',
      text: 'Home'
    },
    {
      linkTo: '/our-work',
      text: 'Our Work'
    },
  ]

  let rightItems = [
    {
      linkTo: '/about-us',
      text: 'About Us'
    },
    {
      linkTo: '/contact',
      text: 'Work With Us',
      component: (props) => <Button {...props} />
    },
  ]

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <Nav
        open={menuOpen}
        toggleMenu={setMenuOpen}
        leftItems={leftItems}
        rightItems={rightItems}
      >
      </Nav>
      <main
        style={{
          boxShadow: '0 8px 34px 13px rgba(0, 0, 0, .2)',
          transform: menuOpen ? 'scale(0.95)' : 'scale(1)',
          // marginTop: menuOpen ? '1rem' : '0rem',
          transitionProperty: 'transform, margin, width'
        }}
        className={`${menuOpen ? "shadow-4xl bg-gray-100 fixed " : ""}  w-full duration-500 over `}
      >
        <div
          style={{ 
            transitionProperty: 'padding, width, overflow, position' 
          }}
          className={`${menuOpen ? " pt-0 md:pt-2 xl:pt-4 z-10 bg-gray-200" : ""} w-full duration-500  min-h-screen relative `}
        >
          <div
            style={{ 
              transitionProperty: 'padding, transform, width, height, z-index, overflow',
            transform: menuOpen ? 'scale(0.95)' : 'scale(1)',

          }}
            className={`${menuOpen ? "shadow-4xl z-40 " : ""} duration-500  overflow-y-scroll w-full h-full absolute `}
          >
            {children}
            <Footer />

          </div>
        </div>
      </main>
      <CookieConsent
        acceptOnScroll={true}
        acceptOnScrollPercentage={15}
      >
        This website uses cookies to anonymously enhance the user experience.
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
