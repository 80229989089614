import React from "react"
import Layout from "@layout/Layout"
import SEO from "@layout/SEO"
import Lightbulb from '@images/lightbulb.png'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import Stag from "../js/Stag"
import { Button } from '@elements'

const IndexPage = ({ data }) => {


  const nodes = data.allFile.nodes

  const design_background = nodes[0].childImageSharp.fluid
  const development_background = nodes[1].childImageSharp.fluid
  const typewriter = nodes[2].childImageSharp.fluid
  const old_camera = nodes[3].childImageSharp.fluid



  return (
    <>
      <SEO title="Expand your horizons" />
      <Layout>

        <Section className="flex flex-col md:flex-row min-h-screen text-primary justify-center max-w-6xl  mx-auto">
          <div className="mx-4 order-2 md:order-1 md:w-1/2 z-10 self-center flex-col flex h-full px-2">
            <h1 className="leading-tight">Bespoke digital <br /> presence consultancy</h1>
            <h3 className="font-normal mt-5 md:font-thin text-accent text-2xl md:text-3xl">We design, develop and enhance <br className="hidden md:block" /> every aspect of your business</h3>
            <Button
              linkTo="/contact"
              style={{
                background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
                backgroundSize: '400% 400%',
                animation: 'gradient 15s ease infinite'
              }}
              className="block mt-6 px-10 text-2xl hover:shadow-3xl transition-shadow duration-500">Start today</Button>
          </div>
          <div className="order-1 md:order-2 w-screen md:w-1/2 min-h-screen absolute md:relative opacity-75 md:opacity-100 h-full" >
            <Stag />
          </div>

        </Section>

        <Section fullWidth className="flex-col justify-center py-12 px-4"
          style={{
            backgroundImage: `linear-gradient(135deg, #006875 0%, #00404A 100%)`

          }}>
            <h2 className="text-gray-100 text-center mb-12">Our Process</h2>

          <div className="flex flex-wrap max-w-6xl mx-auto ">

            { 
            [
              {
              header: "Start with a vision",
              description: "Whether you're at the start of your project or at the end, we are here to help refine, craft and enhance your vision."
              },
              {
              header: "Iterate on concepts",
              description: "Working together to craft incredible designs and experiences for your users or clients, making sure you stand out."
              },
              {
              header: "Bring them to life",
              description: "Using the latest tools and technologies to fill the spaces you need, with the tech and services you require."
              },
              {
              header: "Create growth resources",
              description: "Making content creation a breeze by implementing a strategy from the start designed to provide the most value to your customers. "
              },
            ].map(({header, description}) => {
              return <BulletInfo key={`${header}`} header={header} description={description}/>
            })
            
            }
          </div>

        </Section>

        <Section
          fullWidth
          className="flex flex-col justify-center"
          style={{
            background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
            backgroundSize: '400% 400%',
            animation: 'gradient 15s ease infinite'
          }
          }
        >
          <h3 className="self-center text-center text-bg max-w-3xl px-4 mx-auto">Whether you need us to work on a small one off project or help scale your whole business we are here to work with you night and day.</h3>

          <Button
            style={{
              background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
              backgroundSize: '400% 400%',
              animation: 'gradient 10s ease infinite'
            }}
            linkTo="/contact"
            className="hover:shadow-2xl transition-shadow duration-500 w-full mx-auto py-4 text-2xl mt-12 word-wrap max-w-md">Start working together today</Button>

        </Section>

        <Section bgColor="bg-white" className="relative" fullWidth backgroundImage={<Img className="h-screen w-11/12 right-0 opacity-50 sm:opacity-100" style={{ position: 'absolute' }} fluid={design_background} />}>
          <div className="px-4 flex max-w-6xl mx-auto relative z-10">
            <div className="flex flex-col w-full sm:w-1/2 lg:w-2/5 justify-center">
              <h3 className="mb-4">Designing something beautiful, doesn’t have to be complicated.</h3>
              <p className="leading-loose mb-2">Design is an iterative process. Don't let anyone tell you otherwise! An idea needs to be nurtured and fed, before it can grow into a stage where pen and paper (lets be honest screens and pixels) can be put to use in order to bring it to life.</p>
              <p className="leading-loose">Whether it is designing mobile apps, websites, graphics, or custom artwork for one off documents we work with talented designers and professionals who provide their talents to elevate your business.</p>
              <Button linkTo="/contact" className="my-4 inline-block w-auto hover:shadow-3xl transition-all duration-500 hover:bg-highlight">Let's design something today</Button>
            </div>

          </div>

        </Section>
        <Section className="relative bg-white overflow-hidden" fullWidth bgColor="bg-white">
          <div className=" flex flex-col md:flex-row max-w-6xl mx-auto relative z-10 ">
            <div className="h-64 md:h-full flex flex-col bg-white w-full sm:w-1/2 lg:w-3/5 justify-center ml-auto relative">
              <Img className="md:h-screen w-screen z-20 mr-24 md:mr-0 " 
              style={{ position: 'absolute', right: '0%', }} imgStyle={{ objectFit: 'contain' }} fluid={development_background} />

            </div>
            <div className="flex flex-col bg-white w-full sm:w-1/2 lg:w-2/5 justify-center ml-auto px-4">
              <h3 className="mb-4">Developing something powerful doesn’t have to take forever.</h3>
              <p className="leading-relaxed mb-2">The most important thing to consider when developing a new solution is picking the right tool for the job. If your experience is only working with a 'java hammer' then every solution is going to be a java style nail. </p>
              <p>If we believe that a simple website builder will be more than sufficient for your business then we'll point you in that direction. It is about the best solution for you, not how many large can the project be made.</p>
              <div className="relative z-40">
              <Button linkTo="/contact" className="my-8 md:my-4 block hover:shadow-3xl transition-all duration-500 hover:bg-highlight">Let's develop something today</Button> 
              </div>
           
            </div>

          </div>

        </Section>
        <Section bgColor="bg-white flex-col justify-center" fullWidth>
          <div className="w-full max-w-3xl mx-auto text-center leading-relaxed px-4">
            <h3 className="mb-4">Attracting and engaging customers doesn’t require stress.</h3>
            <p className="mb-4">With over two million blog posts created every day, 24,000 days worth of video uploaded, it’s no longer a matter of churning out content. Our in-house content partners produces compelling content to drive results for clients that cuts through the noise and engages with audiences and raise brand awareness. We help you place relevant content at the heart of your marketing strategy to drive consumer engagement, SEO traffic, brand sentiment, and most importantly, your bottom line.</p>
          </div>

          <div className="max-w-6xl mx-auto flex w-full h-64 justify-around md:justify-center my-6">
            <div className="w-1/2 md:w-1/3 ">
              <Img className="h-64" imgStyle={{ objectFit: 'contain' }} fluid={typewriter} />
            </div>

            <div className="w-1/2 md:w-1/3 h-full ">
              <Img className="h-64" imgStyle={{ objectFit: 'contain' }} fluid={old_camera} />
            </div>

          </div>
          <Button linkTo="/contact" className="my-4 w-0 inline-block hover:shadow-3xl transition-all duration-500 hover:bg-highlight mx-auto">Let's create something today</Button>
        </Section>
      </Layout >
    </>
  )
}

export const Section = ({ children, className, bgColor = "bg-gray-100", fullWidth, fullHeight=true, backgroundImage, ...restProps }) => <section className={`w-full ${ fullHeight ? 'min-h-screen' : ""}  ${bgColor}`}>
  <div {...restProps} className={`flex ${ fullHeight ? 'min-h-screen' : ""}  ${bgColor} text-primary ${fullWidth ? "w-full" : 'max-w-6xl'} mx-auto ${className}`}>
  {backgroundImage && backgroundImage}
  {children}
</div></section>

const BulletInfo = ({ header = "Header", description = "" }) => <div className="flex md:flex-col w-full md:w-1/2 md:px-2 lg:w-1/4 py-8">
  <ul className="flex flex-col md:flex-row justify-between md:pr-16 max-w-md dots">
    <li className="rounded-full h-8 w-8 bg-bg my-1"></li>
    <li className="rounded-full h-4 self-center w-4 bg-bg my-1"></li>
    <li className="rounded-full h-4 w-4 self-center bg-bg my-1"></li>
    <li className="rounded-full h-4 w-4 self-center bg-bg my-1"></li>
    <li className="rounded-full h-4 w-4 self-center bg-bg my-1"></li>
  </ul>
  <div className="flex flex-col pl-4 md:pl-0 md:pt-4">
  <h3 className="text-highlight tracking-wide text-2xl  lg:mt-4 mb-2">{header}</h3>
  <p className="text-gray-100 font-thin">{description || "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, beatae reprehenderit aliquid fugiat iste quod, consectetur perferendis similique placeat harum quam. "}</p>
  </div>
  
</div>



export const query = graphql`
  query IndexPage {
    allFile(filter: {name: {in: ["design-background", "development-background", "old-camera-1", "enhance-typewriter"]}}) {
      nodes {
        id
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`



export default IndexPage
