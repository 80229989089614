import React from 'react'

import { Logo, LogoText} from "@images"

const CombinedLogo = ({className, showText, style}) => {
    return (
        <div style={style} className={className}>
            <img src={Logo} alt="Antler Logo" />
            <img 
                style={{
                    opacity: showText ? 1: 0
                }}
                className="w-full h-4 transition-fast mx-auto" src={LogoText} alt="Antler Logo" />
        </div>
    )
}


export default CombinedLogo