import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Button = ({ linkTo, children, text, className, ...restOfProps }) => {
    return (
        linkTo ? <Link className={className + " w-max-content " + "bg-primary  relative px-4 py-2 text-white"} to={linkTo} {...restOfProps}>
                {children || text}
        </Link>: 
            <button  className={className + " w-max-content " + "bg-primary hover:bg-highlight transition-all duration-500 hover:shadow-xl px-4 py-2 text-white"} {...restOfProps}>
                {children || text}
            </button>
    )
}

Button.propTypes = {

}

export default Button
