import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import {Logo } from '@elements'
import UseBodyLock from '../../hooks/UseBodyLock'


const windowAwait = typeof window !== "undefined" ? window : null



export const ItemRow = ({items, className,...restOfProps}) => {
    return <div className={"transition items-start md:items-center pl-6 md:pl-0 flex-col md:flex-row md:w-2/5 w-full " + className} {...restOfProps}>
        { items.map((item, index) => {
            if (item.component) return item.component({...item, key: `${item.linkTo} ${index}`})
            return <Link key={`${item.linkTo} ${index}`} className="mb-4 md:mb-0 mr-10 hover:text-highlight" key={item.linkTo} to={item.linkTo}>{item.text}</Link>
        })}
    </div>
}



const Nav = ({ open, toggleMenu, leftItems, rightItems  }) => {

    const [ menuHeight, setMenuHeight] = useState(false)
    const menuRef = useRef(0)
 
    const width = windowAwait ? windowAwait.innerWidth : 100

    const measureDistanceFromTop = (e) => {
        console.log(window.screenY)
    }

    UseBodyLock(open)
    useEffect(() => {

        setMenuHeight(window.innerWidth < 768 ?  200 : 0)
        if (menuRef.current) {
            if (menuRef.current.offsetHeight) setMenuHeight(menuRef.current.offsetHeight + 15)
            windowAwait && windowAwait.addEventListener('resize', () => menuRef.current && setMenuHeight(menuRef.current.offsetHeight + 12));
        }
        window.onscroll = () => measureDistanceFromTop()


        return () => {
            window.onscroll = null

        };
    }, [menuRef, menuHeight, setMenuHeight, open])

    if (menuHeight === false) return ""

    return (

        <>
            <div
                className="bg-gray-200">
                <div 
                ref={menuRef}
                className={`${open ? "md:h-32 " : "md:h-0"} flex flex-col md:flex-row w-full justify-between max-w-5xl mx-auto items-center transition-dimensions duration-500  `}
                style={{
                        marginTop: open  ? (width < 768) ? 0 : ""  : (width < 768)  ? `-${menuHeight}px` : "",
                        boxSizing: "border-box",
                        transitionProperty: "margin-top, height"
                    }}
                >   
                    <ItemRow className="md:justify-end lg:justify-around hidden md:flex transition-opacity duration-500" items={leftItems} style={{opacity: open? 1 :0 }} />
                    <Logo className={`z-10 ${open ? "mt-0" : "md:mt-40 absolute top-0 md:relative mt-4"} text-center duration-500 transition-spacing mx-auto`} showText={open} />
                    <ItemRow className="md:hidden flex mt-4 transition-opacity duration-500" items={leftItems} style={{opacity: open ? 1 :0 }} />
                    <ItemRow className="md:justify-start lg:justify-around text-left flex transition-opacity duration-500" items={rightItems} style={{opacity: open? 1 :0 }} />
                </div>
            </div>
            <i onClick={() => toggleMenu(!open)}
                style={{
                    transform: open ? 'rotate(-90deg)' : '',
                    opacity: open ? '0' : '1',
                }}
                className="fixed top-0 left-0 p-5 m-5 text-3xl fa fa-bars z-40 duration-500 transition-all text-primary" />
            <i onClick={() => toggleMenu(!open)}
                style={{
                    transform: open ? '' : 'rotate(90deg)',
                    opacity: open ? '1' : '0',
                }}
                className="fixed top-0 left-0 p-5 m-5 text-4xl fa fa-times z-40 duration-500 transition-all text-primary" />
        </>
    )
}

Nav.defaultProps = {
    open: true,
}

Nav.propTypes = {

}

export default Nav


