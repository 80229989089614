import React from 'react'
import PropTypes from 'prop-types'
import AntlerLogo from '../../images/antler-logo-white.svg'
import {Linked} from '@elements'

const Li = ({ children }) => <li className="my-2">{children}</li>


const Links = ({ list }) => <ul className="mt-4">
    {
        list.map((item, index) => <li key={`${item.linkTo} ${index}`} >
            {item.linkTo ? <Linked linkTo={item.linkTo} className="text-base leading-6 text-gray-500 hover:text-gray-900">
                {item.text}
            </Linked>  : <p className="text-base leading-6 text-gray-500">
                {item.text}</p>}
           
        </li>
        )
    }
</ul>

const SiteLinks = [
    { text: "Home", linkTo: "/"},
    { text: "Our Work", linkTo: "/our-work"},
    { text: "About Us", linkTo: "/about-us"},
    { text: "Work with us", linkTo: "/contact"},
]
const CaseStudyLinks = [
    { text: "Wiserfunding", linkTo: ""},
    { text: "Channel Capital", linkTo: ""},
    { text: "Grand Hotel Fasano", linkTo: ""},
    { text: "A London List", linkTo: ""},
    { text: "Prose", linkTo: ""},
    { text: "Aston Martin & APG Stables", linkTo: ""},
]
const PartnerLinks = [
    { text: "Sixty Secs", linkTo: ""},
    { text: "EJC Content", linkTo: ""}
]




const Footer = props => {
    return <div className="bg-white">
        <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                <div className="xl:col-span-1">
                    <img className="h-20" src={AntlerLogo} alt="Company name" />
                    <h3 className="text-gray-500 text-xl mt-8">Antler Digital</h3>
                    <p className="mt-4 text-gray-500 text-base leading-6">
                        A bespoke digital presence consultancy
                    </p>
                </div>
                <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 xl:mt-0 xl:col-span-2">
                    <div className="md:grid md:grid-cols-1 md:gap-8">
                        <div>
                            <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                Navigation
                            </h4>
                            < Links list={SiteLinks}/>
                        </div>
                        
                    </div>
                    <div className="md:grid md:grid-cols-1 md:gap-8">
                        <div>
                            <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                Case Studies (SOON)
                            </h4>
                            < Links list={CaseStudyLinks}/>
                        </div>
                        
                    </div>
                    <div className="md:grid md:grid-cols-1 md:gap-8">
                        <div>
                            <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                Partners
                            </h4>
                            < Links list={PartnerLinks}/>
                        </div>

                    </div>
                </div>
            </div>
            <div className="mt-12 border-t border-gray-200 pt-8">
                <p className="text-base leading-6 text-gray-400 xl:text-center">
                    &copy; {new Date().getFullYear()} Antler Enterprises, Ltd. <br/> All rights reserved.
                </p>
            </div>
        </div>
    </div>
}

// const Footer = props => {
//     return (
//         <footer className=" bg-primary text-gray-200 py-24  ">
// {/* 
//             <div className="max-w-5xl mx-auto grid grid-cols-3 pt-6 ">

//             </div> */}
//             <div className="max-w-5xl mx-auto grid grid-span-3 grid-cols-4 ">
//             <img className="mx-auo" src={AntlerLogo} alt="Antler Logo in white" />
//                     <ul className="">
//                         <Li>Antler Digital</Li>
//                         <Li>London</Li>
//                         <Li>United Kingdom</Li>
//                         <Li>team@antler.digital</Li>
//                     </ul>
//                     <ul className="mx-auto">
//                         <Li className="font-bold ">Links</Li>
//                         <Li>Home</Li>
//                         <Li>Our Work</Li>
//                         <Li>About us</Li>
//                         <Li>Work with us</Li>
//                     </ul>
//                     <ul className="mx-auto">
//                         <Li className="font-bold">Case Studies</Li>
//                         <Li>Wiserfunding</Li>
//                         <Li>A London List</Li>
//                         <Li>Channel Capital</Li>
//                         <Li>Lake Garda Hotel</Li>
//                     </ul>
//                 </div>
//             {/* <h4 className="text-sm pb-12 text-center">Antler Digital is the trading name of Antler Enterprises Limited, a UK company offering distributed digital consultancy services globally. </h4> */}
//         </footer>
//     )
// }

Footer.propTypes = {

}

export default Footer
